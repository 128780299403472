<script setup lang="ts">
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { EmitsEnum } from '~~/src/constants/emits'
import { utilArray } from '~~/src/utils/utilArray'

const props = defineProps({
	entries: {
		type: Object as PropType<TranslationKey[]>,
		default: [],
	},
	testIds: {
		type: Object as PropType<string[]>,
		default: [],
	},
	disabled: Boolean,
	selectedOptions: { type: Object as PropType<Number[]>, default: [] },
	customSizes: { type: Object as PropType<Number[]>, default: [1] },
	disabledOptions: { type: Object as PropType<Number[]>, default: [] },
	buttonSizes: { type: String as PropType<ButtonSizes>, default: ButtonSizes.TEXT_M },
})
const { entries, selectedOptions, disabled, disabledOptions, customSizes, buttonSizes, testIds } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])

const isSelected = (index: number) => !disabled.value && selectedOptions.value.some((el) => el == index)

const background = computed(() => {
	if (disabled.value) {
		return 'bg-dark-blue-100'
	}
	return 'bg-sky-50'
})

const buttonDisabled = (index: number) => disabled.value || disabledOptions.value.some((el) => el === index)
const buttonBackground = (index: number) => {
	if (buttonDisabled(index)) {
		return ''
	}
	return isSelected(index) ? 'bg-sky-600 shadow' : 'hover:bg-sky-300'
}

const textColor = (index: number) => {
	if (disabled.value) {
		return 'text-dark-blue-300'
	}

	return isSelected(index) ? 'text-white' : 'text-sky-600'
}

const onClick = (index: number) => {
	if (selectedOptions.value.some((el) => el === index)) {
		emit(
			EmitsEnum.Click,
			selectedOptions.value.filter((el) => el != index)
		)
	} else {
		emit(EmitsEnum.Click, utilArray.mergeSortedArrays(selectedOptions.value, [index]))
	}
}

const getTemplateColumns = (): string => {
	const repeatAmount = Math.floor(entries.value.length / customSizes.value.length)
	const repeatCols = customSizes.value.map((el) => el.toString() + 'fr').join(' ')

	let template = `grid-template-columns: repeat(${repeatAmount}, ${repeatCols})`

	const extraColsAmount = entries.value.length % customSizes.value.length
	for (let i = 0; i < extraColsAmount; i++) {
		template += ` ${customSizes.value[i]}fr`
	}

	return template + ';'
}
</script>

<template>
	<div class="grid w-full gap-0.5 rounded-md p-0.5" :class="background" :style="getTemplateColumns()">
		<CommonButton
			v-for="(entry, index) in entries"
			:key="entry"
			:data-testid="testIds?.[index] ?? entry"
			:size="buttonSizes"
			@click="onClick(index)"
			class="rounded"
			:disabled="buttonDisabled(index)"
			:class="buttonBackground(index)"
		>
			<CommonText :class="textColor(index)" :text="entry as TranslationKey" />
		</CommonButton>
	</div>
</template>
